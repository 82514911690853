import React from 'react'

import './index.css'
import Title from '../../components/Title'

const data = [
  {
    body: 'Premier single "Attendre / Tout ce que je veux" disponible sur bandcamp'
  }
]

const News = ({ id }) => {
  return (
    <div className="section" id={id}>
      <Title label="News" />
      <div className="section-content">
        {data.map(({ title, body }) => (
          <div>
            {title && <h4>{title}</h4>}
            <p>{body}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default News
