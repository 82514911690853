import React from 'react'

import './index.css'
import Title from '../../components/Title'

const Bio = ({ id }) => (
  <div className="section" id={id}>
    <Title label="Bio" />
    <div className="section-content">
      <p>Qui sont ces (jeunes) gens qui font du rock, juste du rock, du vrai !?</p>
      <p>Avant les Aspis pailletés il y avait Nicolo, puis le Baron Nicolo. Il est maintenant Duc. </p>
      <p>
        <a href="https://www.info-groupe.com/nicolo/" target="_blank">Un peu d’histoire ></a>
      </p>
    </div>
  </div>
)

export default Bio
