import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Navbar from './sections/Navbar'
import News from './sections/News'

import './App.css'
import Dates from './sections/Dates'
import Bio from './sections/Bio'
import Medias from './sections/Medias'
import Contact from './sections/Contact'
import Header from './sections/Header'

function App() {
  return (
    <div className="App">
      {/*<header>*/}
      {/*  <Navbar />*/}
      {/*</header>*/}
      <main>
        <Container>
          <Row>
            <Col>
              <Header id="header" />
              <News id="news" />
              <Dates id="date" />
              <Bio id="bio" />
              <Medias id="medias" />
              <Contact id="contact" />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  )
}

export default App
