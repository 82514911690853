import React from 'react'

import './index.css'

const Header = ({ id }) => (
  <div className="header-container" id={id}>
    <h1>Les Aspis Pailletes</h1>

    <div className="media-icons">
      <a href="https://www.facebook.com/lesaspispailletes" target="_blank">
        <img src="/icons/facebook.svg" alt="icons facebook" />
      </a>
      <a href="https://lesaspispailletes.bandcamp.com/releases" target="_blank">
        <img src="/icons/bandcamp.svg" alt="icons bandcamp" />
      </a>
    </div>
  </div>
)

export default Header
