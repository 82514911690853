import React from 'react'

import './index.css'
import Title from '../../components/Title'

const Contact = ({ id }) => (
  <div className="section" id={id}>
    <Title label="Contact" />
    <div className="section-content">
      <a href="mailto:lesaspispailletes@mailo.com">lesaspispailletes@mailo.com ></a>
      <div className="divider" />
      <p>
        <b>Booking</b> : Les productions du loir - <a href="mailto:lpdl@mailo.com">lpdl@mailo.com ></a>
      </p>
    </div>
  </div>
)

export default Contact
