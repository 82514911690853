import React from 'react'
import { Row, Col } from 'react-bootstrap'

import './index.css'
import Title from '../../components/Title'

const comingDates = [
  {
    date: "21/06/2022",
    nom: "Fête de la Musique (avec Les Branque's)",
    lieu: "24 rue de la Parcheminerie, Paris (75)",
  },
]

const passedDates = [
  // {
  //   date: "21/06/2020",
  //   nom: "Concert privé",
  //   lieu: "Romorantin (41)",
  // },
]

const Dates = ({ id }) => (
  <div className="section" id={id}>
    <Title label="Dates" />

    <div className="list-group">
      {/*<h3>Concerts à venir</h3>*/}
      <div>
        {comingDates.length > 0 ? (
          <ul>
            {comingDates.map((date) => (
              <li>
                <b>{date.date}</b> - {date.nom} - {date.lieu}
              </li>
            ))}
          </ul>
        ) : (
          <p className="tba">
            Le plus possible dès que possible :-)
          </p>
        )}
      </div>
    </div>

    {/*<div className="passed-dates">*/}
    {/*  <Row className="h3-subtitle">*/}
    {/*    <h3>Concerts passés</h3>*/}
    {/*  </Row>*/}
    {/*  <Row className="justify-content-md-center">*/}
    {/*    <Col>*/}
    {/*      {passedDates.length > 0 ? (*/}
    {/*        <ul className="list-group">*/}
    {/*          {passedDates.map((date) => (*/}
    {/*            <li className="list-group-item">*/}
    {/*              {date.date} - {date.nom} - {date.lieu}*/}
    {/*            </li>*/}
    {/*          ))}*/}
    {/*        </ul>*/}
    {/*      ) : (*/}
    {/*        <p className="tba">TBA</p>*/}
    {/*      )}*/}
    {/*    </Col>*/}
    {/*  </Row>*/}
    {/*</div>*/}
  </div>
)

export default Dates
