import React, { useState } from 'react'

import './index.css'
import Title from '../../components/Title'
import { Row, Col } from 'react-bootstrap'
import Carousel, { Modal, ModalGateway } from 'react-images'

const images = [
  // { src: "images/photo_1.jpg" },
  // { src: "images/photo_2.jpg" },
  // { src: "images/photo_3.jpg" },
]

const Medias = ({ id }) => {
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const openLightbox = (index) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div className="section" id={id}>
      <Title label="Medias" />

      {/*<div className="media-section">*/}
      {/*  <Row className="h3-subtitle">*/}
      {/*    <h3>Photos</h3>*/}
      {/*  </Row>*/}
      {/*  <Row></Row>*/}
      {/*</div>*/}

      <div className="media-section section-content">
        <Row>
          <h3>Photos / Vidéos</h3>
        </Row>
        <Row>
          <p>À venir</p>
          {/*<div className="preview-wrapper">*/}
          {/*  {images.map((image, index) => (*/}
          {/*    <img src={image.src} alt={`gallerie ${index}`} onClick={() => openLightbox(index)} />*/}
          {/*  ))}*/}
          {/*</div>*/}
          {/*<ModalGateway>*/}
          {/*  {viewerIsOpen && (*/}
          {/*    <Modal onClose={closeLightbox}>*/}
          {/*      <Carousel currentIndex={currentImage} views={images} />*/}
          {/*    </Modal>*/}
          {/*  )}*/}
          {/*</ModalGateway>*/}
        </Row>
      </div>

      <div className="media-section section-content">
        <Row>
          <h3>Audio</h3>
        </Row>
        <Row>
          <p>
            <a href="https://lesaspispailletes.bandcamp.com/" target="_blank">
              https://lesaspispailletes.bandcamp.com >
            </a>
          </p>
          {/*<Col className="player-container">*/}
          {/*  <iframe*/}
          {/*    title="audio-player"*/}
          {/*    style={{ border: 0, height: "241px" }}*/}
          {/*    src="https://bandcamp.com/EmbeddedPlayer/album=4279445059/size=large/bgcol=333333/linkcol=0f91ff/artwork=small/transparent=true/"*/}
          {/*    seamless*/}
          {/*  >*/}
          {/*    <a href="https://imperialpigeons.bandcamp.com/album/demo-de-confinement">*/}
          {/*      Demo de confinement by Imperial Pigeons*/}
          {/*    </a>*/}
          {/*  </iframe>*/}
          {/*</Col>*/}
        </Row>
      </div>
    </div>
  )
}

export default Medias
